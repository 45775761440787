import React, { useEffect, useState } from 'react'

import CallQIndicator from 'components/dialer/CallQIndicator'
import UserStatus from 'components/dialer/UserStatus'
import Select from 'components/form/Select'
import { PhoneNumber } from 'constants/types'
import { useDispatch, useSelector } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import * as voiceActions from 'store/actions/voiceActions'
import TwilioManager from 'helpers/TwilioManager'
import ToolTip from 'react-tooltip-lite'
import Modal from 'components/layout/Modal'
import UserSettings from './ModalsContents/UserSettings'
import User from 'helpers/User'
import CallsHistory from './Tabs/CallsHistory'
import WorkizLang from 'language/WorkizLang'

type headerModalTypes = "" | "settings" | "queue"

function Header(props: { queueCounter: number, callFromOptions: Array<PhoneNumber> }) {
    const [modalOpen, setModalOpen] = useState<headerModalTypes>("")
    const [callFromNumbersOptions, setCallFromNumbersOptions] = useState<Array<PhoneNumber>>(props.callFromOptions);
    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    const dispatch = useDispatch()

    useEffect(() => {
        if (voiceState.onCall || voiceState.ringing) {
            setModalOpen("")
        }
    }, [voiceState.onCall, voiceState.ringing])

    const setCallingFrom = (value: string) => {
        TwilioManager.getInstance().setCallingFrom(value)
        dispatch(voiceActions.setVoiceState({ callingFrom: value }))
    }

    const getCallQueueTitle = () => {
        if (props.queueCounter === 1) {
            return "1 call in queue"
        }
        if (props.queueCounter > 1) {
            return `${props.queueCounter} calls in queue`
        }
        return "No calls in queue"
    }

    const getTitleComponent = () => {
        return (
            <div className={`flex-row modal-header`}>
                <div  onClick={() => setModalOpen("")}>
                    <i className={"wi-chevron-left font-16"} />
                    <div className={"flex-one modal-title "}>{WorkizLang.get().dialer.callsInQueue}</div>
                </div>
               
               
            </div>
        )
    }

    const onSearchNumbers = (searchStr: string) => {
        const filteredOptions = props.callFromOptions.filter(numberOption => (numberOption.value.substring(2)).includes(searchStr));
        setCallFromNumbersOptions(filteredOptions);
    }

    const getModalContent = () => {
        switch (modalOpen) {
            case "settings":
                return <UserSettings />

            case "queue":
                return <CallsHistory active queueView setActiveCallModal={() => { }} />

            default:
                break;
        }
        return <span />
    }

    return (
        <>
            <div className={"header center flex-row dialPad"}>
                <ToolTip content={"Set availability"}>
                    <UserStatus username={User.get().userData?.user?.name} onClick={() => setModalOpen("settings")} />
                </ToolTip>
                <ToolTip content={"Calling From123"} className={""} >
                    <Select title="Calling From123" onSelect={setCallingFrom} selectedValue={voiceState.callingFrom || ""} options={callFromNumbersOptions}
                    useSearchBar={true} 
                    onSearchBarChange={onSearchNumbers} 
                    searchBarPlaceHolder={WorkizLang.get().data.searchNumbers}
                    modalExtraClass={'full-screen-fixed-size'}
                    />
                </ToolTip>
                <ToolTip content={getCallQueueTitle()} className={"alignLeft"} >
                    <CallQIndicator onClick={() => setModalOpen("queue")} queueCounter={props.queueCounter} />
                </ToolTip>
            </div>
            <Modal
                show={modalOpen !== ""}
                title={modalOpen === "queue" ? WorkizLang.get().dialer.callsInQueue : User.get().userData?.user?.name}
                onClose={() => setModalOpen("")}
                fullscreen={modalOpen === "queue"}
                titleComponent={modalOpen === "queue" ?getTitleComponent():undefined}
            >
                {getModalContent()}
            </Modal>
        </>
    )
}

export default Header

const AppRouting = {
    Home: "/",
    Splash: "/splash",
    Login: "/login",
    _2FA: "/2fa",
    NotAllowed: "/not-allowed",
    NoNumbers: "/no-number",
    Main: "/main",
}

const  WebRouting = {
    Home: "/",
    Splash: "/splash",
    Login: "/login",
    _2FA: "/2fa",
    NotAllowed: "/not-allowed",
    NoNumbers: "/no-number",
    Main: "/main",
}

const Routing = process.env.REACT_APP_WEB ?WebRouting : AppRouting;
export default Routing;